import React from 'react'
import {useDispatch, useSelector} from "react-redux"
import {Link} from "react-router-dom"

import {AppBar, Typography, Toolbar} from '@material-ui/core'
import {Drawer, Divider, IconButton} from '@material-ui/core'
import {List, ListItem, ListItemIcon, ListItemText} from '@material-ui/core'
import IconHistory from '@material-ui/icons/AccessTime'
import IconAccount from '@material-ui/icons/AccountCircle'
import IconNewAnalysis from '@material-ui/icons/CreditCard'
import IconLogout from '@material-ui/icons/ExitToApp'
import IconFAQ from '@material-ui/icons/HelpOutline'
import MenuIcon from '@material-ui/icons/Menu'
import Stack from '@mui/material/Stack'

import {logout} from "../../store/actions/authActions"
import {useStyles} from '../../themes/styles'

const Header = (props) => {
  const dispatch = useDispatch()
  const auth = useSelector(state => state.auth)
  const classes = useStyles()

  const [state, setState] = React.useState({
    drawerOpen: false,
  })

  const handleLogout = () => {
    dispatch(logout(props.socket))
  }

  const toggleDrawer = (newState) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return
    }
    setState({ ...state, drawerOpen: newState })
  }

  const list = () => (
    <div
      className={classes.list}
      role="presentation"
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
    >
      <List>
        <ListItem button component={Link} to="/analysis">
          <ListItemIcon><IconNewAnalysis/></ListItemIcon>
          <ListItemText primary="New Analysis"/>
        </ListItem>
        {auth.name ?
          <ListItem button component={Link} to="/history">
            <ListItemIcon><IconHistory/></ListItemIcon>
            <ListItemText primary="History" />
          </ListItem>
        :
          <ListItem button component={Link} to="/faq">
            <ListItemIcon><IconFAQ className={classes.clickBlack}/></ListItemIcon>
            <ListItemText primary="FAQ" />
          </ListItem>
        }
      </List>
      <Divider/>
      <List>
        {auth.name ?
          <ListItem button component={Link} to="/account">
            <ListItemIcon><IconAccount/></ListItemIcon>
            <ListItemText primary="My Account"/>
          </ListItem>
        :
          <ListItem button component={Link} to="/register">
            <ListItemIcon><IconAccount/></ListItemIcon>
            <ListItemText primary="Register"/>
          </ListItem>
        }
        {auth.name ?
          <ListItem button onClick={() => handleLogout()}>
            <ListItemIcon><IconLogout/></ListItemIcon>
            <ListItemText primary="Logout" />
          </ListItem>
        :
          <ListItem button component={Link} to="/login">
            <ListItemIcon><IconLogout/></ListItemIcon>
            <ListItemText primary="Sign In"/>
          </ListItem>
        }
      </List>
    </div>
  )

  return ( <>
    <Drawer anchor="left" open={state["drawerOpen"]} onClose={toggleDrawer(false)}>
      {list()}
    </Drawer>
    <AppBar position="static" width="100%">
      <Toolbar>
        <IconButton onClick={toggleDrawer(true)} edge="start" className={classes.menuButton} color="inherit" aria-label="menu">
          <MenuIcon />
        </IconButton>
        <Typography variant="h5" className = {classes.rootHeader}>
          <Link to="/" className={classes.linkStyle}>
            Stack My Deck
          </Link>
        </Typography>
        {auth.name ? (<>
          <Typography variant="subtitle2" className={classes.rootHeader + " " + classes.name}>
            Logged in as {auth.name}
          </Typography>
        </>) : (<>
          <Link to="/login" className={classes.linkStyle}>
          <Stack direction="row" spacing={1} alignItems="center">
            <IconAccount className={classes.spacingRight0}/>
            <Typography variant="subtitle1" className={classes.rootHeader + " " + classes.name}>
              Sign In
            </Typography>
          </Stack>
          </Link>
        </>)}
      </Toolbar>
    </AppBar>
  </> )
}

export default Header
