import React from 'react'
import {Link} from "react-router-dom"

import {Typography, Box} from "@material-ui/core"
import Grid from '@mui/material/Grid'
import useMediaQuery from '@mui/material/useMediaQuery'

import theme from '../../../themes/_main'
import {useStyles} from '../../../themes/styles'

import img_cards from '../../../images/cards_02.jpg'
import img_travel from '../../../images/travel_01.jpg'

function Steps() {
  const desktop = useMediaQuery(`(min-width:${theme.breakpoints.values.md}px)`)
  const classes = useStyles()

  return( <>
    <Box className={classes.spacing + " " + classes.blockFull}>
      <Grid container spacing={2}>
        <Grid item md={6}>
          <Typography variant="h4" align="center" className={desktop ? "" : classes.blockFull}>How It Works</Typography>
          <Box direction="row" alignItems="flexStart" spacing={1} className={classes.spacing + " " + classes.spacingLeft}>
            <Typography variant="h5" className={classes.fontScript}>
              Step 1:
            </Typography>
            <Typography variant="h6">
            <Link to="/register">
              Register
            </Link>
            {" "} for a free account.
            </Typography>
          </Box>
          <Box direction="row" alignItems="flexStart" spacing={1} className={classes.spacing + " " + classes.spacingLeft}>
              <Typography variant="h5" className={classes.fontScript}>
              Step 2:
            </Typography>
            <Typography variant="h6">
              Get free credits by taking a third-party survey, or buy credits directly with an existing credit card or other online payment method.
            </Typography>
          </Box>
          <Box direction="row" alignItems="flexStart" spacing={1} className={classes.spacing + " " + classes.spacingLeft}>
              <Typography variant="h5" className={classes.fontScript}>
              Step 3:
            </Typography>
            <Typography variant="h6">
              Spend your credits on a new analysis. For more details on analysis types, visit the{" "}
              <Link to="/faq">
                FAQ
              </Link>.
            </Typography>
          </Box>
          <Box direction="row" alignItems="flexStart" spacing={1} className={classes.spacing + " " + classes.spacingLeft}>
              <Typography variant="h5" className={classes.fontScript}>
              Step 4:
            </Typography>
            <Typography variant="h6">
              Answer a few questions about your spending/banking/travel habits and preferences.
            </Typography>
          </Box>
          <Box direction="row" alignItems="flexStart" spacing={1} className={classes.spacing + " " + classes.spacingLeft}>
              <Typography variant="h5" className={classes.fontScript}>
              Step 5:
            </Typography>
            <Typography variant="h6">
              Submit your answers and review your results.
            </Typography>
          </Box>
          <Box direction="row" alignItems="flexStart" spacing={1} className={classes.spacing + " " + classes.spacingLeft}>
              <Typography variant="h5" className={classes.fontScript}>
              Step 6:
            </Typography>
            <Typography variant="h6">
              Choose which new credit card(s) you would like and apply.
            </Typography>
          </Box>
          <Box direction="row" alignItems="flexStart" spacing={1} className={classes.spacing + " " + classes.spacingLeft}>
              <Typography variant="h5" className={classes.fontScript}>
              Step 7:
            </Typography>
            <Typography variant="h6">
              Stack your deck and maximize your rewards.
            </Typography>
          </Box>
        </Grid>

        <Grid item md={6}>
          <img src={img_cards} alt="A stack of credit cards" className={classes.image}/>
          {desktop ?
            <img src={img_travel} alt="A traveler at an airport" className={classes.image + " " + classes.spacing}/>
          :
            null
          }
        </Grid>
      </Grid>
    </Box>
  </>)
}

export default Steps
