const singularPlural = (count, singular, plural) => {
  if (count == 1) {
    return `${count} ${singular}`
  }
  else {
    return `${count} ${plural}`
  }
}

export default singularPlural
