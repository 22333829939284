import React from 'react'
import {useSelector} from "react-redux"
import {BrowserRouter, Route, Switch, Link} from "react-router-dom"

import {ToastContainer} from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

import Stack from '@mui/material/Stack'
import {Box, Container, Typography} from "@material-ui/core"

import Account from './components/pages/Account'
import Analysis from './components/pages/Analysis'
import Contact from './components/pages/Contact'
import Faq from './components/pages/Faq'
import Home from './components/pages/Home'
import History from './components/pages/History'
import Login from './components/pages/Login'
import Privacy from './components/pages/Privacy'
import Purchase from './components/pages/Purchase'
import PwRecover from './components/pages/PwRecover'
import PwReset from './components/pages/PwReset'
import Register from './components/pages/Register'
import Terms from './components/pages/Terms'

import Steps from './components/pages/homeViews/Steps'

import Header from './components/common/Header'
import Loading from './components/common/Loading'

import {useStyles} from './themes/styles'


import { createTheme } from '@material-ui/core/styles'
import { ThemeProvider } from '@material-ui/styles'

import colors from './themes/colors'

const theme = createTheme({
  palette: {
    primary: {
      main: colors.primary.main,
    },
    secondary: {
      main: colors.secondary.main,
    },
  },
})

function Views(props) {
  const appState = useSelector(state => state.app)
  const classes = useStyles()

  return (
    <ThemeProvider theme={theme}>
      <Loading />
      <BrowserRouter>
        <ToastContainer/>
        <Container maxWidth={false} disableGutters={true}>
          <div className={classes.flexParentPage}>
            <div className={classes.flexStatic}>
              <Header/>
            </div>
            {appState.connected ?
              <React.StrictMode>
                <div className={classes.flexStretch}>
                  <Container className={classes.contentStyle} maxWidth="sm">
                    <Switch>
                      <Route
                        path="/account" exact
                        render={() => <Account socket={props.socket}/>}
                      />
                      <Route
                        path="/analysis" exact
                        render={() => <Purchase socket={props.socket}/>}
                      />
                      <Route
                        path="/analysis/:idAnalysis" exact
                        render={(propsRender) => <Analysis {...propsRender} socket={props.socket}/>}
                      />
                      <Route
                        path="/contact" exact
                        render={() => <Contact socket={props.socket}/>}
                      />
                      <Route
                        path="/faq" exact
                        render={() => <Faq/>}
                      />
                      <Route
                        path="/history" exact
                        render={() => <History socket={props.socket}/>}
                      />
                      <Route
                        path="/login" exact
                        render={() => <Login socket={props.socket}/>}
                      />
                      <Route
                        path="/privacy" exact
                        render={() => <Privacy socket={props.socket}/>}
                      />
                      <Route
                        path="/recover-password" exact
                        render={(props) => <PwRecover socket={props.socket} {...props}/>}
                      />
                      <Route
                        path="/reset-password" exact
                        render={() => <PwReset socket={props.socket}/>}
                      />
                      <Route
                        path="/register" exact
                        render={() => <Register socket={props.socket}/>}
                      />
                      <Route
                        path="/terms" exact
                        render={() => <Terms/>}
                      />
                      <Route
                        path="/" exact
                        render={() => <Home socket={props.socket}/>}
                      />
                    </Switch>
                  </Container>
                  <Switch>
                    <Route
                      path="/" exact
                      render={() => <Steps/>}
                    />
                  </Switch>
                </div>
                <div className={classes.flexStatic}>
                  <Box className={classes.spacing + " " + classes.blockFull + " " + classes.blockPrimary + " " + classes.spreadBoxCenter}>
                    <Stack className={`${classes.spacingHalf} ${classes.spacingBottom}`}>
                      <Typography variant="body1" component={Link} to="/terms" className={classes.linkStyle + " " + classes.spacingHalf}>Terms of Use</Typography>
                      <Typography variant="body1" component={Link} to="/privacy" className={classes.linkStyle + " " + classes.spacingHalf}>Privacy Policy</Typography>
                    </Stack>
                    <Stack>
                      <Typography variant="body1" component={Link} to="/faq" className={classes.linkStyle + " " + classes.spacingHalf}>FAQ</Typography>
                      <Typography variant="body1" component={Link} to="/contact" className={classes.linkStyle + " " + classes.spacingHalf}>Contact Us</Typography>
                    </Stack>
                  </Box>
                  <Box className={classes.blockFull + " " + classes.blockPrimary + " " + classes.spreadBoxCenter + " " + classes.linkStyle} sx={{paddingBottom: "10px"}}>
                    Copyright 2025 Stack My Deck, LLC
                  </Box>
                </div>
              </React.StrictMode>
            :
              null
            }
          </div>
        </Container>
      </BrowserRouter>
    </ThemeProvider>
  )
}

export default Views
