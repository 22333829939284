import * as React from 'react'
import {useDispatch} from "react-redux"
import {useHistory} from "react-router-dom"

import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'

import axios from "axios"
import {toast} from "react-toastify"

import {urlApi} from "../../../api"
import creditCosts from '../../../constants/creditCosts'
import singularPlural from '../../../fns/singularPlural'
import {loadingToggle} from "../../../store/actions/appActions"

const DialogSpend = (props) => {
  const dispatch = useDispatch()
  const history = useHistory()

  const analysisTypeCost = () => {
    switch (props.analysisType) {
      case "next":
      case "full":
        return creditCosts[props.analysisType]
      default:
        return ""
    }
  }

  const analysisTypeString = () => {
    switch (props.analysisType) {
      case "next":
        return "Next Card"
      case "full":
        return "Full Deck"
      default:
        return ""
    }
  }

  const handleClose = () => {
    props.setOpen("")
  }

  const handleConfirm = () => {
    dispatch(loadingToggle(true))
    axios
    .post(`${urlApi}/analysis/new`, {
      analysisType: props.analysisType,
    }, {"withCredentials": true})
    .then(resp => {
      dispatch(loadingToggle(false))
      const idAnalysis = resp.data
      history.push(`/analysis/${idAnalysis}`)
    })
    .catch(error => {
      toast.error(error.response?.data, {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 5000,
      })
      dispatch({
        type: "LOADING_TOGGLE",
        isLoading: false,
      })
    })
  }

  return (
    <div>
      <Dialog open={Boolean(props.open)} onClose={handleClose}>
        <DialogTitle>New {analysisTypeString()} Analysis ({analysisTypeCost()} Credits)</DialogTitle>
        <DialogContent>
          <DialogContentText>
            A {analysisTypeString()} analysis will cost {analysisTypeCost()} of your available {singularPlural(props.credits, "credit", "credits")}, and will leave you with {singularPlural(props.credits - analysisTypeCost(), "credit", "credits")}. This action cannot be undone. Are you sure you want to continue? 
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleConfirm} variant="contained">Continue</Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default DialogSpend