import React, {useEffect} from 'react'
import {useDispatch, useSelector} from "react-redux"
import {useParams} from 'react-router'
import {Redirect, useHistory, useLocation} from "react-router-dom"

import axios from "axios"
import {toast} from "react-toastify"

import {Button} from "@material-ui/core"
import {ThemeProvider, createTheme} from '@mui/material/styles'
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft"
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight"
import Box from '@mui/material/Box'
import LinearProgress from '@mui/material/LinearProgress'

import inputViews from '../../constants/inputViews'
import calculateStatusBoa from '../../fns/calculateStatusBoa'
import findIndexMax from '../../fns/findIndexMax'
import getInputViewSkips from '../../fns/getInputViewSkips'
import {saveProgress} from "../../store/actions/analysisActions"
import {loadingToggle} from "../../store/actions/appActions"
import {urlApi} from "../../api"
import colors from '../../themes/colors'
import {useStyles} from '../../themes/styles'

import DialogConfirm from './analysisViews/dialogConfirm'
import Result from './Result'

const theme = createTheme({
  palette: {
    primary: {
      main: colors.primary.main,
    },
    secondary: {
      main: colors.secondary.main,
    },
  },
})

const Analysis = (props) => {
  const dispatch = useDispatch()
  const history = useHistory()
  var {location} = useLocation()
  const {idAnalysis} = useParams()
  const auth = useSelector(state => state.auth)
  const classes = useStyles()

  const [openConfirm, setOpenConfirm] = React.useState(false)
  const [state, setState] = React.useState({
    disableNext: false,
    indexInputView: 0,
    inputs: location,
    loaded: false,
    newOther: "",
    progress: 0,
    reachedLastView: false,
    skipViews: {},
    viewReady: false,
  })

  const pageState = {
    state,
    setState,
  }

  const findIndexContinue = (inputs) => {
    const skipViews = getInputViewSkips(inputs)
    const tierLevel = inputs.tier.level
    const completedInputs = inputs.completedInputs
    for (let iFindCon = 0; iFindCon < inputViews.length; iFindCon++) {
      if ((tierLevel >= inputViews[iFindCon].tier) && !completedInputs[inputViews[iFindCon].name] && !skipViews[inputViews[iFindCon].name]) {
        return {
          skipViews,
          indexContinue: iFindCon,
        }
      }
    }
    return {
      skipViews,
      indexContinue: 0,
    }
  }

  const findIndexMin = (tierLevel) => {
    for (let iFindMin = 0; iFindMin < inputViews.length; iFindMin++) {
      if (tierLevel >= inputViews[iFindMin].tier) {
        return iFindMin
      }
    }
  }

  const findPageToDisplay = (index, delta) => {
    index += delta
    if ((state.inputs.tier.level >= inputViews[index].tier) && (!state.skipViews[inputViews[index].name])) {
      setState({
        ...state,
        indexInputView: index,
        disableNext: false,
        progress: (index / (findIndexMax(state.inputs.tier.level) + 1))*100,
        reachedLastView: state.reachedLastView || (index === findIndexMax(state.inputs.tier.level)),
      })
    }
    else {
      findPageToDisplay(index, delta)
    }
  }

  const handleBack = (event) => {
    findPageToDisplay(state.indexInputView, -1)
    window.scrollTo(0,80)
    dispatch(saveProgress(state.inputs, props.socket.id))
  }

  const handleNext = (event) => {
    var stateWhole = {
      ...state
    }
    stateWhole.inputs.completedInputs[inputViews[state.indexInputView].name] = true
    setState({
      stateWhole
    })
    findPageToDisplay(state.indexInputView, 1)
    window.scrollTo(0,80)
    dispatch(saveProgress(state.inputs, props.socket.id))
  }

  const handleSave = (event) => {
    dispatch(loadingToggle(true))
    dispatch(saveProgress(state.inputs, props.socket.id))
  }

  const handleSubmit = () => {
    setOpenConfirm(true)
  }

  const handleSubmitConfirm = () => {
    dispatch(loadingToggle(true))
    let spending = state.inputs.userInput.spending
    const domains = [
      {
        primary: "domestic",
        other: "otherDomestic",
      },
      {
        primary: "foreign",
        other: "otherForeign",
      },
    ]
    for (let iDomain = 0; iDomain < domains.length; iDomain++) {
      spending[domains[iDomain].primary].travel = spending[domains[iDomain].primary].travel_camping + spending[domains[iDomain].primary].travel_tours
      let sumOther = 0
      for (let iOther = 0; iOther < spending.otherStr.length; iOther++) {
        sumOther += spending[domains[iDomain].other][iOther]
      }
      spending[domains[iDomain].primary].other = sumOther
    }
    state.inputs.userInput.multipliers.status.boa = calculateStatusBoa(state.inputs.userInput.boa)

    const reqBody = {
      idAnalysis: state.inputs._id,
      cardsAnalyze: state.inputs.cardsAnalyze,
      cardsWallet: state.inputs.cardsWallet,
      userInput: state.inputs.userInput,
    }
    axios
    .post(`${urlApi}/analysis/submitAnalysis`, reqBody, {"withCredentials": true})
    .then(resp => {
      toast("ANALYSIS SUBMITTED", {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 1000,
      })

    })
    .catch(error => {
      toast.error(error.response?.data, {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 1000,
      })
      dispatch({
        type: "LOADING_TOGGLE",
        isLoading: false,
      })
    })
  }

  useEffect(() => {
    dispatch(loadingToggle(true))

    props.socket.on(`clickSubmit_${idAnalysis}`, () => {
      dispatch(loadingToggle(true))
    })

    props.socket.on(`progressSaved_${idAnalysis}`, (idSocket) => {
      if (String(idSocket) !== String (props.socket.id)) {
        history.push('/analysis')
      }
    })

    props.socket.on('purchases', (updatedPurchases) => {
      for (let i = 0; i < updatedPurchases.length; ++i) {
        if (String(updatedPurchases[i]._id) === String(idAnalysis)) {
          if (!updatedPurchases[i].isStarted) {
            // history.push('/analysis')
          }
        }
      }
    })

    props.socket.on(`submittedAnalysis_${idAnalysis}`, (tier, inputs, result) => {
      var stateWhole = {
        ...state
      }
      stateWhole.inputs = inputs

      stateWhole.inputs.tier = tier
      stateWhole.inputs.isComplete = true
      stateWhole.inputs.result = result
      stateWhole.loaded = true
      setState(stateWhole)

      dispatch(loadingToggle(false))
    })

    if (location) {
      const cont = findIndexContinue(state.inputs)
      setState({
        ...state,
        indexInputView: cont.indexContinue,
        loaded: "from state",
        progress: (cont.indexContinue / (findIndexMax(state.inputs.tier.level) + 1))*100,
        reachedLastView: state.reachedLastView || (cont.indexContinue === findIndexMax(state.inputs.tier.level)),
        skipViews: cont.skipViews,
      })
      dispatch(loadingToggle(false))
    }
    else {
      axios
      .post(`${urlApi}/analysis/getAnalysis`, {idAnalysis}, {"withCredentials": true})
      .then(resp => {
        if (resp.data) {
          if (!resp.data.isSubmitted) {
            const cont = findIndexContinue(resp.data)
            pageState.setState({
              ...pageState.state,
              indexInputView: cont.indexContinue,
              inputs: resp.data,
              loaded: "from mongo",
              progress: (cont.indexContinue / (findIndexMax(resp.data.tier.level) + 1))*100,
              reachedLastView: pageState.state.reachedLastView || (cont.indexContinue === findIndexMax(resp.data.tier.level)),
              skipViews: cont.skipViews,
            })
            dispatch(loadingToggle(false))
            toast("ANALYSIS LOADED", {
              position: toast.POSITION.BOTTOM_RIGHT,
              autoClose: 1000,
            })
          }
        }
      })
      .catch(error => {
        toast.error(error.response?.data, {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 1000,
        })
        dispatch({
          type: "LOADING_TOGGLE",
          isLoading: false,
        })
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth.name])


  if (!auth.name) return <Redirect to="/"/>

  if (state.loaded) {
    if (state.inputs.isComplete) {
      return (
        <ThemeProvider theme={theme}>
          <Result pageState={pageState}/>
        </ThemeProvider>
      )
    }
    else {

      return (
        <ThemeProvider theme={theme}>

          <DialogConfirm
          open={openConfirm}
          setOpen={setOpenConfirm}
          handleSubmitConfirm={handleSubmitConfirm}
          setState={setState}
          state={state}
          {...state}
          />

          <Box sx={{ width: '100%' }}>
            <LinearProgress variant="determinate" value={state.progress} />
          </Box>

          <Box
          component="span"
          className={classes.spreadBoxFull + " " + classes.spacing}
          >

            <Button
              variant="contained"
              color="primary"
              onClick={handleBack}
              disabled={state.indexInputView === findIndexMin(state.inputs.tier.level)}
            >
              <KeyboardArrowLeft/> Back
            </Button>

            {(state.reachedLastView && state.indexInputView !== findIndexMax(state.inputs.tier.level)) ?
              <Button
                variant="contained"
                onClick={handleSubmit}
              >
                Submit
              </Button>
            :
              null
            }

            {state.indexInputView !== findIndexMax(state.inputs.tier.level) ?
              <Button
                variant="contained"
                color="primary"
                onClick={handleNext}
                disabled={state.disableNext}
              >
                Next <KeyboardArrowRight/>
              </Button>
            :
              <Button
                variant="contained"
                color="primary"
                onClick={handleSubmit}
              >
                Submit
              </Button>
            }
          </Box>

          <Box className={classes.shadowBox + " " + classes.spacing}>
            <Box
            component={inputViews[state.indexInputView].template}
            pageState={pageState}
            />

          </Box>
          <Box
          component="span"
          className={classes.spreadBoxFull + " " + classes.spacing}
          >

            <Button
              variant="contained"
              color="primary"
              onClick={handleBack}
              disabled={state.indexInputView === findIndexMin(state.inputs.tier.level)}
            >
              <KeyboardArrowLeft/> Back
            </Button>

            <Button
              variant="contained"
              onClick={handleSave}
            >
              Save Progress
            </Button>

            {state.indexInputView !== findIndexMax(state.inputs.tier.level) ?
              <Button
                variant="contained"
                color="primary"
                onClick={handleNext}
                disabled={state.disableNext}
              >
                Next <KeyboardArrowRight/>
              </Button>
            :
              <Button
                variant="contained"
                color="primary"
                onClick={handleSubmit}
              >
                Submit
              </Button>
            }
          </Box>

        </ThemeProvider>
      )
    }
  }
  return ( <>
    {/*Page still loading*/}
  </> )
}

export default Analysis
