import * as React from 'react'

import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'

const DialogSurvey = (props) => {

  const handleClose = () => {
    props.setOpen(false)
  }

  return (
    <div>
      <Dialog open={Boolean(props.open)} onClose={handleClose}>
        <DialogTitle>Earn Credits</DialogTitle>
        <DialogContent>
          <iframe src={props.surveyUrl}/>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} variant="contained">Close</Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default DialogSurvey