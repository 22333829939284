import React, {useEffect} from 'react'
import {useDispatch, useSelector} from "react-redux"
import {generatePath} from "react-router"
import {Link, Redirect, useHistory} from "react-router-dom"

import axios from "axios"
import moment from "moment"
import {toast} from "react-toastify"

import {ThemeProvider} from '@mui/material/styles'
import IconGift from '@material-ui/icons/CardGiftcard'
import IconGo from '@material-ui/icons/DoubleArrow'
import IconRestart from '@material-ui/icons/Replay'
import Button from "@material-ui/core/Button"
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import Tooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'

import creditCosts from '../../constants/creditCosts'
import singularPlural from '../../fns/singularPlural'

import {urlApi} from "../../api"
import {loadingToggle} from "../../store/actions/appActions"
import theme from '../../themes/_main'
import {useStyles} from '../../themes/styles'

import DividerMargin from '../styled/DividerMargin'
import DividerMarginMulti from '../styled/DividerMarginMulti'

import DialogGift from './purchaseViews/dialogGift'
import DialogNoSurvey from './purchaseViews/dialogNoSurvey'
import DialogReset from './purchaseViews/dialogReset'
import DialogSpend from './purchaseViews/dialogSpend'
import DialogStripe from './purchaseViews/dialogStripe'
import DialogSurvey from './purchaseViews/dialogSurvey'

const humanReadableFromTier = (tier) => {
  if (tier === undefined) {
    return "ANALYSIS"
  }
  const type = (tier?.fullAnalysis ? "Full Deck" : "Next Card")
  return (type + " Analysis")
}

const Purchase = function DotsMobileStepper(props) {
  const dispatch = useDispatch()
  const history = useHistory()
  const auth = useSelector(state => state.auth)
  const classes = useStyles()

  const [analysisType, setAnalysisType] = React.useState("")
  const [credits, setCredits] = React.useState(auth.credits)
  const [openGift, setOpenGift] = React.useState("")
  const [openNoSurvey, setOpenNoSurvey] = React.useState(false)
  const [openReset, setOpenReset] = React.useState("")
  const [openSpend, setOpenSpend] = React.useState("")
  const [openStripe, setOpenStripe] = React.useState(false)
  const [openSurvey, setOpenSurvey] = React.useState(false)
  const state = {
    checkingOut: false,
    fullAnalysis: true,
    redirection: {},
    tierLevel: 0,
  }
  const [pollfishLoaded, setPollfishLoaded] = React.useState(false)
  const [purchases, setPurchases] = React.useState([])
  const [surveyAvailable, setSurveyAvailable] = React.useState(false)
  const [fullCount, setFullCount] = React.useState(0)
  const [nextCount, setNextCount] = React.useState(0)
  const [stripeReady, setStripeReady] = React.useState(false)
  const [surveyUrl, setSurveyUrl] = React.useState("")
  const strAnalysis = ""

  const disabledButton_modify = (purchase) => {
    return (!purchase.isStarted && purchase.isGift)
  }

  const handleModify = async (purchase) => {
    if (purchase.isStarted) {
      await setOpenReset(purchase._id)
    }
  }

  const handleNewAnalysis = async (newAnalysisType) => {
    await setAnalysisType(newAnalysisType)
    setOpenSpend(true)
  }

  const launchSurvey = (analysisTypeClick) => {
    if (false && analysisTypeClick === analysisType) {
      console.log("launchSurvey = (analysisTypeClick)")
      // window.Pollfish.showFullSurvey()
      setOpenSurvey(true)
    }
    else {
      // setSurveyUrl(`https://theoremreach.com/respondent_entry/direct?api_key=fdeee08fa467b004f22d16575056&user_id=${auth._id}&transaction_id=${analysisTypeClick}`)
      // setOpenSurvey(true)

      dispatch(loadingToggle(true))
      setAnalysisType(analysisTypeClick)
      var theoremReachConfig = {
        apiKey: "fdeee08fa467b004f22d16575056",
        userId: auth._id,
        // idfa: "USERS_APPLE_ADVERTISING_ID", // optional. If iOS, set the Apple IDFA to ensure the most number of surveys are available
        // gpsId: "USERS_GOOGLE_ADVERTISING_ID", // optional. If Android, set the Google Advertising ID 
        onRewardCenterOpened: onRewardCenterOpened,
        onReward: onReward,
        onRewardCenterClosed: onRewardCenterClosed,
        transaction_id: analysisTypeClick
      };
      function onRewardCenterOpened(){
        console.log("onRewardCenterOpened");
      }
  
      function onReward(data){
        console.log("onReward: " + data.earnedThisSession);
      }
  
      function onRewardCenterClosed(){
        console.log("onRewardCenterClosed");
        dispatch(loadingToggle(false))
      }

      var TR = new window.TheoremReach(theoremReachConfig)
      TR.showRewardCenter()

      // dispatch(loadingToggle(true))
      // setPollfishLoaded(false)
      // setAnalysisType(analysisTypeClick)
      // var pollfishConfig = {
      //   api_key: "3d4fd121-8ecb-4c14-a91f-0b4ac35816ba",
      //   user_id: auth._id,
      //   placement_key: "74194249-6908-4bf4-a369-c2ae4619aab2",
      //   // debug: true,
      //   clickId: `${analysisTypeClick}__${auth._id}__${props.socket.id}`,
      //   ready: customReady,
      //   closeCallback: customSurveyClosed,
      //   userNotEligibleCallback: customUserNotEligible,
      //   closeAndNoShowCallback: customCloseAndNoShow,
      //   surveyCompletedCallback: customSurveyFinished,
      //   surveyAvailable: customSurveyAvailable,
      //   surveyNotAvailable: customSurveyNotAvailable,
      // }
      // if (analysisType) {
      //   window.Pollfish.restart(pollfishConfig)
      // }
      // else {
      //   window.Pollfish.start(pollfishConfig)
      // }

      // // Survey is ready
      // function customReady(){
      //   dispatch(loadingToggle(false))
      //   setPollfishLoaded(true)
      // }

      // // User closed the survey
      // function customSurveyClosed(){}

      // // User is not eligible
      // function customUserNotEligible(){
      //   noSurvey()
      // }

      // // Survey has been completed
      // function customSurveyFinished(data){
      //   setAnalysisType("completed")
      // }

      // // Close and hide the Pollfish panel
      // function customCloseAndNoShow(){}

      // // Survey is available
      // function customSurveyAvailable(data){
      //   window.Pollfish.showFullSurvey()
      // }

      // // Survey not available
      // function customSurveyNotAvailable(){
      //   noSurvey()
      // }
    }
  }

  const noSurvey = () => {
    //setAnalysisType("no survey")
    //setOpenNoSurvey(true)
  }

  if (!auth.name && purchases.length) {
    //setPurchases([])
  }

  const updatePurchases = (updatedData) => {
    let cntFull = 0
    let cntNext = 0
    for (var iPurchase = 0; iPurchase < updatedData.length; iPurchase++) {
      if (updatedData[iPurchase].tier.fullAnalysis) {
        cntFull++
      }
      else {
        cntNext++
      }
    }
    setPurchases(updatedData)
    setNextCount(cntNext)
    setFullCount(cntFull)
  }

  const script_stripe = document.createElement('script')
  script_stripe.setAttribute("id", "script_stripe")
  script_stripe.src = "https://js.stripe.com/v3/pricing-table.js"
  script_stripe.async = true
  script_stripe.onload = () => {
    setStripeReady(true)
  }
  document.body.appendChild(script_stripe)


  useEffect(() => {
    props.socket.on('credits', (numCredits) => {
      setCredits(numCredits)
    })

    props.socket.on('pollfishCallbackComplete', (id) => {
      history.push(`/analysis/${id}`)
    })

    props.socket.on('purchases', (updatedPurchases) => {
      updatePurchases(updatedPurchases)
    })

    if (auth.name) {
      console.log("auth.name")
      console.log(auth)
      dispatch(loadingToggle(true))
      axios
      .post(`${urlApi}/analysis/getPurchases`, {}, {"withCredentials": true})
      .then(respPurchases => {
        console.log("respPurchases")
        updatePurchases(respPurchases.data)
        dispatch(loadingToggle(false))
        axios
        .get('https://api.ipify.org?format=json')
        // .then(respIp => respIp.json())
        .then(respIp => {
            console.log(respIp.data.ip);
            axios
            .get(`https://api.theoremreach.com/api/publishers/v1/user_details?api_key=fdeee08fa467b004f22d16575056&user_id=${auth._id}&ip=${respIp.data.ip}`)
            .then(respSurveys => {
              console.log(respSurveys)
              if(respSurveys.data.surveys_available) {
                const script_jquery = document.createElement('script')
                script_jquery.setAttribute("id", "script_jquery")
                script_jquery.src = "https://code.jquery.com/jquery-3.2.1.min.js"
                script_jquery.async = true
                script_jquery.onload = () => {
                  const script_tr = document.createElement('script')
                  script_tr.setAttribute("id", "script_tr")
                  script_tr.src = "https://s3.amazonaws.com/cdn.theoremreach/v3/theorem_reach.min.js"
                  script_tr.async = true
                  script_tr.onload = () => {
                    setSurveyAvailable(true)
                  }
                  document.body.appendChild(script_tr)
                }
                document.body.appendChild(script_jquery)
              }
              else {
                noSurvey()
              }
            })
            .catch(err => {
              console.log(err)
            })
        })
        .catch(error => {
            console.log('IP Error:', error);
        });
      })
      .catch(error => {
        toast.error(error.response?.data, {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 5000,
        })
        dispatch({
          type: "LOADING_TOGGLE",
          isLoading: false,
        })
      })
    }
    else {
      updatePurchases([])
    }
    return () => {}
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth.name])

  if (!auth.name) {
    return (
      <Typography variant="h5">
        You must <Link to="/login">log in</Link> or <Link to="/register">create a free account</Link> to access.
    </Typography>
    )
  }

  if (state["redirection"]._id) {
    return <Redirect to={{
      pathname: generatePath("/analysis/:idAnalysis", {idAnalysis: state["redirection"]._id}),
      location: state["redirection"],
    }}/>
  }
  else if (state["checkingOut"]) {
    return (
      <>
      </>
    )
  }
  else {
    return (
      <Box>
        <ThemeProvider theme={theme}>
          <DialogGift open={openGift} setOpen={setOpenGift} strAnalysis={strAnalysis}/>
          <DialogNoSurvey open={openNoSurvey} setOpen={setOpenNoSurvey}/>
          <DialogReset open={openReset} setOpen={setOpenReset}/>
          <DialogSpend open={openSpend} setOpen={setOpenSpend} analysisType={analysisType} credits={credits}/>
          <DialogStripe open={openStripe} setOpen={setOpenStripe} ready={stripeReady} idUser={auth._id}/>
          <DialogSurvey open={openSurvey} setOpen={setOpenSurvey} surveyUrl={surveyUrl}/>
          <Typography variant="h4">
            You have {singularPlural(credits, "credit", "credits")} available
          </Typography>
          <Box className={classes.shadowBox} sx={{marginTop: "25px", marginBottom: "25px"}}>
            <Typography variant="h5">
              Get Credits
            </Typography>
            <Box textAlign="center">
              <Tooltip followCursor={true} title={surveyAvailable ? "" : "No Surveys Available"}>
                <span>
                  <Button
                    className={classes.spacing}
                    variant="contained"
                    color="primary"
                    style={{width:"80%"}}
                    onClick={() => launchSurvey("")}
                    disabled={!surveyAvailable}
                  >
                    <Box>
                      <Typography variant="h5" align="center">
                        Earn free credits
                      </Typography>
                      <Typography variant="string">
                        by taking a third-party survey
                      </Typography>
                    </Box>
                  </Button>
                </span>
              </Tooltip>
            </Box>
            <Box textAlign="center">
              <Button
                className={classes.spacing}
                variant="contained"
                color="primary"
                style={{width:"80%"}}
                onClick={() => setOpenStripe(true)}
                disabled={!stripeReady}
              >
                <Box>
                  <Typography variant="h5" align="center">
                    Buy credits
                  </Typography>
                  <Typography variant="string">
                    with an online payment method
                  </Typography>
                </Box>
              </Button>
            </Box>
          </Box>
          <Box className={classes.shadowBox} sx={{marginTop: "25px", marginBottom: "25px"}}>
            <Typography variant="h5">
              Spend Credits
            </Typography>
            <Box textAlign="center">
              <Tooltip followCursor={true} title={credits < creditCosts.next ? "Not Enough Credits" : ""}>
                <span>
                  <Button
                    className={classes.spacing}
                    variant="contained"
                    color="primary"
                    style={{width:"80%"}}
                    disabled={credits < creditCosts.next}
                    onClick={() => handleNewAnalysis("next")}
                  >
                    <Box>
                      <Typography variant="h5" align="center">
                        Next Card Analysis
                      </Typography>
                      <Typography variant="string">
                        {creditCosts.next} Credits
                      </Typography>
                    </Box>
                  </Button>
                </span>
              </Tooltip>
            </Box>
            <Box textAlign="center">
            <Tooltip followCursor={true} title={credits < creditCosts.full ? "Not Enough Credits" : ""}>
                <span>
                  <Button
                    className={classes.spacing}
                    variant="contained"
                    color="primary"
                    style={{width:"80%"}}
                    disabled={credits < creditCosts.full}
                    onClick={() => handleNewAnalysis("full")}
                  >
                    <Box>
                      <Typography variant="h5" align="center">
                        Full Deck Analysis
                      </Typography>
                      <Typography variant="string">
                        {creditCosts.full} Credits
                      </Typography>
                    </Box>
                  </Button>
                </span>
              </Tooltip>
            </Box>
          </Box>
          {purchases.length ?
            <div>
              <Typography variant="h4" sx={{}}>Open Analyses</Typography>
              <DividerMargin/>
              {purchases.map((step, index) => (
                <Box className={classes.spacing} key={"purchase_" + index}>
                  <Stack direction="row" justifyContent="space-between">
                    <Typography variant="h5">{humanReadableFromTier(step.tier)}</Typography>
                  </Stack>
                  <Typography variant="h6">{"Initialized " + moment(step.datePurchased).fromNow()}</Typography>
                  <Stack direction="row" justifyContent="space-between">
                    {step.isStarted ? 
                      <Button
                        className={classes.spacing + " " + classes.colorLight}
                        variant="contained"
                        onClick={() => handleModify(step)}
                        disabled={disabledButton_modify(step)}
                      >
                        {step.isStarted ?
                          <>
                            <IconRestart className={classes.spacingRight}/>
                            {"Reset"}
                          </>
                        :
                          <>
                            <IconGift className={classes.spacingRight}/>
                            {"Give as Gift"}
                          </>
                        }
                      </Button>
                    :
                      <div></div>
                    }
                    
                    <Button
                      className={classes.spacing}
                      variant="contained"
                      color="primary"
                      component={Link} to={"/analysis/" + step._id}
                    >
                      {step.isStarted ? "Continue " : "Go!"}
                      <IconGo className={classes.spacingLeft}/>
                    </Button>
                  </Stack>
                  <DividerMargin/>
                </Box>
              ))}
            </div>
          :
            <></>
          }
        </ThemeProvider>
      </Box>
    )
  }
}

export default Purchase
